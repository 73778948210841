.App_no_target {
	height: 95% !important;
	width: 100% !important;
	top: 0px !important;
	right: 0px !important;
	left: 0px !important;
	bottom: 0px !important;
	position: absolute;
	padding: 0px !important;
}

.App {
	height: 70% !important;
	width: 100% !important;
	top: 0px !important;
	right: 0px !important;
	left: 0px !important;
	bottom: 0px !important;
	position: absolute;
	padding: 0px !important;
}
