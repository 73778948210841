/* .ontodia-paper-area__watermark {
    display: none;
}         */

/* .ontodia-accordion-item--vertical:first-child[style] {
    display: none !important;
}     */

.ontodia {
	height: 100% !important;
}

.ontodia-input-group .ontodia-form-control:first-child {
	top: 0px;
	height: 34.4px;
}

.ontodia-accordion-item--horizontal:last-child[style] {
	display: none !important;
}

/* .ontodia-accordion-item__header 
   {
      visibility: hidden;
      position: relative;
  }
  
  .ontodia-accordion-item__header:after {
      visibility: visible;
      position: absolute;
      content: "Search";
      font-size: 16px;
      background: #ffffff;
      cursor: default;
      top: 34%;
      left: 13px;
      margin-top: -2px;
  } */

/* .ontodia-toolbar {
      background: rgb(241, 241, 241);
  } */

.main {
	width: '100%';
	height: '100%';
	background-color: 'white';
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
}

.ontodia-standard-template__thumbnail-icon {
	max-height: 56px !important;
	max-width: 56px !important;
}

.Arca {
	height: 100% !important;
	width: 100% !important;
	top: 0px !important;
	right: 0px !important;
	left: 0px !important;
	bottom: 0px !important;
	position: absolute;
	padding: 0px !important;
}

.ontodia-input-group .ontodia-form-control {
	display: table-cell;
	position: relative;
	z-index: 2;
	float: left;
	width: 100%;
	height: 100%;
	padding: 7px;
	margin-bottom: 0;
}

.ontodia-btn {
	margin-left: 5px !important;
}

.ontodia-toolbar {
	background: rgb(212, 212, 212) !important;
}

.ontodia-toolbar .span {
	color: white;
}

.ontodia-btn-default {
	background-color: #ffffff !important;
	border-color: rgba(129, 11, 11, 0) !important;
	color: #000 !important;
	border-radius: 4px;
	font-family: sans-serif;
	font-size: xx-small;
}

.ontodia-label {
	font-family: sans-serif !important;
	font-size: xx-small;
	color: black !important;
}

.ontodia select {
	font-family: sans-serif !important;
	font-size: xx-small;
	top: 2px;
	position: relative;
}

.ontodia-input-group .ontodia-form-control {
	top: 0px;
	height: 33px;
}

.ontodia-accordion-item__header {
	display: none;
}

.ontodia-standard-template__iri-value a {
	color: black !important;
}
