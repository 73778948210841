.Rectangle {
  width: -webkit-fill-available;
  background: #fff;
  color: black;
  border: 0px solid black;
  filter: drop-shadow(2px 4px 6px black);
  float: right;
  padding: 10px;
  bottom: 0px;
  position: relative;
  overflow-y: auto;
  height: 43%;
}

.RectangleClose {
  width: -webkit-fill-available;
  background: #fff;
  color: black;
  border: 0px solid black;
  filter: drop-shadow(2px 4px 6px black);
  float: right;
  padding: 10px;
  bottom: 0px;
  position: relative;
  overflow-y: auto;
  height: 43%;
}

.RectangleOpen {
  z-index: 1005542 !important;
  width: -webkit-fill-available;
  background: #fff;
  color: black;
  border: 0px solid black;
  filter: drop-shadow(2px 4px 6px black);
  float: right;
  padding: 10px;
  bottom: 0px;
  z-index: 200;
  position: fixed;
  overflow-y: auto;
  height: 95%;
}

span {
  color: black;
  font-weight: bold;
}

.buttonAI {
  padding: 8px !important;
  margin-right: 12px !important;
  background: #ff9e0d !important;
}

.buttonAI_no {
  padding: 8px !important;
  margin-right: 12px !important;
  background: #ff9e0d65 !important;
}

.container {
  width: 200px;
  height: 100%;
  margin: 0 auto 0;
}
.pulse-button {
  position: relative;
  width: fit-content;
  height: fit-content;
  border: none;
  box-shadow: 0 0 0 0 rgb(225 228 0 / 70%);
  border-radius: 5px;
  background-color: #e84c3d;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}
.pulse-button:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
}
@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

.contMeta {
  text-align: center;
  justify-content: center;
  display: flex;
  margin: 10px;
  align-items: baseline;
  flex-wrap: nowrap;
  flex-direction: row;
}

.titleM {
  font-weight: bolder;
  color: #757575;
  display: inline;
}

.exM {
  padding: 0px;
  margin: 0px;
}

.boxM {
  margin: 4px;
  background: antiquewhite;
  padding: 8px;
  font-size: 10px;
}

.infoNosel {
  background: #e8e8e8;
  width: -webkit-fill-available;
  color: #8d0000;
  padding: 6px;
  text-align: center;
  height: -webkit-fill-available;
}

#viewer-container {
  position: absolute;
  padding-top: 10px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.pdf-thumbnail-bar {
  position: fixed;
  top: 20% !important;
  height: 100vh;
  width: 140px;
  overflow: auto;
  background-color: #f1f3f7;
  border-right: #dce0e3 1px solid;
  padding: 22px 24px;
  transition: all 0.5s;
}

#pdfSearchbar {
  position: fixed;
  display: flex;
  bottom: 20px;
  align-items: center;
  justify-content: space-between;
  left: 200px;
  width: 300px;
  max-height: 36px;
  border-radius: 3px;
  z-index: 9999;
  padding: 10px;
}

#pdfSearchbar input {
  font-size: 13px;
  background-color: #313b51;
  border: none;
  color: white !important;
}

#pdfSearchbar span {
  color: white;
  font-size: 11px;
}
