.documentButton {
	right: 248px;
	position: absolute;
	z-index: 200;
	top: 0px;
	border-radius: 4px;
	margin-top: 5px;
	background: #f2e799;
	margin-bottom: 5px;
}

.boxDocu {
	position: absolute;
	top: 40px;
	left: calc(100% - 200px);
	overflow-y: auto;
	padding: 10px;
	text-align: center;
	z-index: 200;
	background: #f0f8ffc7;
	width: 200px;
	height: calc(100% - 40px);
}

.valueDoc {
	padding: 1px;
	background: white;
	margin: 5px;
	border-radius: 5px;
	border-style: outset;
}

.filterBox {
	background: #ffffff;
	border-radius: 4px;
	margin: 5px;
	height: 100px;
	overflow: auto;
	border-style: ridge;
}

button.filterNoSel {
	width: -webkit-fill-available;
	background: white;
	border-style: hidden;
}

button.filterNoSel:hover {
	width: -webkit-fill-available;
	background: rgb(218, 218, 218);
	border-style: hidden;
}

button.filterSel {
	width: -webkit-fill-available;
	background: rgb(255 209 209);
	border-style: hidden;
}

.openFilter {
	background: aqua !important;
	min-width: auto;
	margin-left: 6px !important;
}

.closeFilter {
	background: rgb(210, 255, 255) !important;
	min-width: auto;
	margin-left: 6px !important;
}
