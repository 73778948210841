.container_slider {
	position: relative;
	top: 0px;
}

.Connect\(Login\)-avatar-2 {
	margin-left: auto;
	margin-right: auto;
	background-color: #009cff !important;
}

.MuiButton-containedPrimary {
	color: #fff;
	background-color: #009cff !important;
}

.MuiButton-label {
	width: 100%;
	display: inherit;
	font-size: 10px;
	align-items: inherit;
	justify-content: inherit;
}

input#email {
	margin: 0px;
	width: inherit;
	height: fit-content;
	padding: 18px;
}

.titolo {
	font-weight: bolder;
	text-align-last: center;
	color: #7b0000;
	padding-top: 15px;
}

/* .logoImg {
	vertical-align: middle;
	height: 60px;
} */

.MuiButton-containedPrimary {
	color: #fff;
	background-color: #ff7600 !important;
}
