.header {
	padding: 5px;
	z-index: 11111;
	top: 0px;
	background-color: none;
	text-align: right;
	height: 40px;
	right: 0px;
	position: absolute;
	width: 75px;
}

.logout {
	text-align: center;
	right: 19px;
	position: absolute;
	top: 0px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.MuiAppBar-colorPrimary {
	font-family: sans-serif !important;
	font-size: xx-small !important;
	font-size: 12px !important;
	margin-top: 0px;
	margin-bottom: 0px;
}

.MuiButton-containedPrimary {
	color: #fff;
	background-color: #ffffff !important;
	height: 30px;
	font-size: 12px !important;
	width: 75px !important;
}
