.finestra {
	padding: 20px;
	overflow-y: auto;
	text-align: center;
	text-align-last: center;
}

.buttonContent {
	padding: 8px !important;
	margin-right: 12px !important;
	margin-bottom: 12px !important;
	margin-top: 12px !important;
	background: aquamarine !important;
}
