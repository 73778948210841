.loadButton {
	right: 176px;
	position: absolute;
	z-index: 200;
	top: 0px;
	border-radius: 4px;
	margin-top: 5px;
	background: #f2e799;
	margin-bottom: 5px;
}
